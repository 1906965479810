import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import { StoreListingProps } from './models';
import { getUrl, getTarget } from '../../utils/browser';
import 'styles/main.scss';
import './StoreListing.scss';

const StoreListing: FC<{ data: StoreListingProps }> = ({
  data: {
    storeListing: { title, image, stores, seoMetaTitle, seoMetaKeywords, seoMetaDescription },
    homepage: { warning },
  },
}) => (
  <Layout headerTransparent>
    <Seo {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }} />
    <PageSchema
      type="WebPage"
      name={seoMetaTitle}
      data={{
        metaTitle: seoMetaTitle,
        metaDescription: seoMetaDescription,
        metaKeywords: seoMetaKeywords,
      }}
    />
    <div className="store-listing">
      <section className="store-listing__banner text-center">
        <Image imageData={image} alt={image.altText} />
      </section>
      <section id="store-listing__middle">
        <div className="container">
          <div className="row">
            <div className="col pt-4">
              <h4 className="text-uppercase text-center py-2 px-2 px-md-5 mx-2 mx-md-5 mb-4">
                {warning}
              </h4>
              <h2 className="line-primary">{title}</h2>
            </div>
          </div>
          <div className="row pb-5">
            {stores.map(({ link: [item], ...props }, index) => {
              const cols = !index ? 'col-12 store-listing__col-first' : 'col-6 col-lg-4 my-1';

              return (
                <div className={cols} key={index}>
                  <a
                    href={getUrl(item)}
                    target={getTarget(item)}
                    className="btn btn-secondary bg-transparent text-center d-block store-listing__item"
                  >
                    <Image imageData={props.image} alt={props.image.altText} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    homepage {
      warning
    }
    storeListing {
      title
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      image {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
      }
      stores {
        link {
          url
          queryString
          target
          name
        }
        image {
          fallbackUrl
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 390)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 390)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 390)
            }
          }
        }
      }
    }
  }
`;

export default StoreListing;
